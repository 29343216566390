/*
Template Name: Lexa - Responsive Bootstrap 4 Admin Dashboard
Author: Themesbrand
Version: 1.0.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

/*PROPIO INICIO*/
.fontWhite{
  color: white !important;
}

.list-group-item a, list-group-item a:hover{
  color: white !important;
}

.card-body img{
  width: 100%;
  height: auto;
  cursor: pointer;
}

.imgPointer{
  cursor: pointer;
}

#page-topbar{
  z-index: 9 !important;
}

.examForm fieldset{
  padding: 10px 0 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.examForm fieldset .form-check{
  padding-top: 5px;
  padding-bottom: 5px;
}

.list-group .list-line, .list-line .list-group-item,
.list-line .list-group-item:first-child, .list-line .list-group-item:last-child{
  border-left: none !important;
  border-right: none !important;
  border-radius: 0px !important;
}

.list-line .list-group-item:hover{
  background-color: #f5f5f5 !important;
}

@media (max-width: 992px){
  body[data-layout="horizontal"] .page-content {
      padding: calc(98px + 60px) calc(24px / 2) 60px calc(24px / 2) !important;
  }
}

.width75{
  width:75% !important;
}

.width33{
  width:33% !important;
}

.width20{
  width:20% !important;
}

.vAlignMiddle td{
  vertical-align: middle;
}

.timeline-right::before, .timeline-left::before{
  transform: translateX(-50%) rotate(90deg) !important;
  width: 150px !important;
}

.foroLeft{
  float:left !important;
}

.foroLeft .cd-date {
  float: right !important;
  left: 33% !important;
  right: auto !important;
  text-align: right !important;
}
.foroLeft::before{
  content: '' !important;
  position: absolute !important;
  top: 16px !important;
  left: 100% !important;
  right: auto !important;
  height: 0 !important;
  width: 0 !important;
  border: 12px solid transparent !important;
  border-left: 12px solid #f8f9fa !important;
  border-color: transparent !important;
  border-left-color: #f8f9fa !important;
}

.foroRight{
  float:right !important;
}

.foroRight::before{
  content: '' !important;
  position: absolute !important;
  top: 16px !important;
  right: 100% !important;
  left: auto !important;
  height: 0 !important;
  width: 0 !important;
  border: 12px solid transparent !important;
  border-right: 12px solid #f8f9fa !important;
  border-color: transparent !important;
  border-right-color: #f8f9fa !important;
}

.foroRight .cd-date {
  float: left !important;
  right: 33% !important;
  left: auto !important;
  text-align: left !important;
}

.noMargin{
  margin: 0 !important;
}

.noMarginTop{
  margin-top: 0 !important;
}

.noPaddingTop{
  padding-top: 0 !important;
}

.formBox{
  border: 1px solid #dee2e6!important;
}

.react-chat-frame{
  position: unset !important;
  bottom: auto !important;
}

.react-chat-container, .react-chat-row, .react-chat-viewerBox{
  width: 100% !important;
  min-width: 100% !important;
}

.react-chat-container{
  border-radius: 0.25rem !important;
  box-shadow: 0 -3px 31px 0 #0000000d, 0 6px 20px 0 #00000005 !important;
}

.sun-editor .se-toolbar {
  z-index: 0 !important;
}

.borderTop{
  border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.bodyLogin{
  /* The image used */
   background-image: url('../images/back.jpg');
   height: 100%;
   background-position: bottom;
   background-repeat: no-repeat;
   background-size: cover;
}

.originalSize{
  width: auto !important;
}

div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #eee;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    border-radius: 2px;
    float: left;
}

button.ReactTags__remove {
    border: none;
    color: #aaa;
    margin-left: 5px;
}



/*PROPIO FIN*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "variables";
@import "../../../node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";


// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/dragula";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/switch";
@import "custom/plugins/datepicker";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/summernote";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/flot";
@import "custom/plugins/morris";
@import "custom/plugins/c3";
@import "custom/plugins/chartist";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";


// Pages
@import "custom/pages/authentication";
@import "custom/pages/email";
@import "custom/pages/directory";
@import "custom/pages/timeline";

@media all{
  /*.noPaddingTop{
    padding-top: 0px !important;
  }

  .noMarginTop{
    margin-top: 0px !important;
  }*/

  .noPaddingBottom{
    padding-bottom: 0px !important;
  }

  .noMarginBottom{
    margin-bottom: 0px !important;
  }
}
